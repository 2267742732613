<template>
    <section>
        <div class="content">
            <h3 class="mt-2 mb-5 ml-2 is-hidden-desktop"><i class="fal fa-file-signature fa-fw mr-3"></i>Tickets & Documents</h3>

            <div class="content mt-6 mb-6" v-if="!documents && !user">
                <h5 class="has-text-grey"><i class="fal fa-tennis-ball fa-spin mr-3"></i>Loading your travel documents. Please wait...</h5>
            </div>
            <div v-else>
                <div v-if="user">
                    <div class="white-container content" >
                        <h6><i class="far fa-passport fa-fw mr-2"></i>Passport Information</h6>

                        <div class="columns">
                            <div class="column">
                                <b-field label="Passport Number" label-position="inside">
                                    <b-input v-model="user.passport_number"/>
                                </b-field>
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column">
                                <b-field label="Issue Date" label-position="inside">
                                    <b-input type="date" v-model="user.passport_issue_on"/>
                                </b-field>
                            </div>
                            <div class="column">
                                <b-field label="Expire Date" label-position="inside">
                                    <b-input type="date" v-model="user.passport_expire_on"/>
                                </b-field>
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column">
                                <b-field label="Passport Nationality" label-position="inside">
                                    <b-input v-model="user.passport_nationality"/>
                                </b-field>
                            </div>
                            <div class="column">
                                <b-field label="Issuing Authority" label-position="inside">
                                    <b-input v-model="user.passport_authority"/>
                                </b-field>
                            </div>
                        </div>


                    </div>
                    <div class="white-container content">
                        <h6><i class="far fa-plane fa-fw mr-2"></i>Traveler Information</h6>

                        <div class="columns">
                            <div class="column">
                                <b-field label="TSA Precheck/Known Traveler Number" label-position="inside">
                                    <b-input v-model="user.traveler_ktn"/>
                                </b-field>
                            </div>
                        </div>
                    </div>
                    <div class="mt-6 has-text-right">
                        <b-button type="is-primary" @click="save" :disabled="processing"><i class="fas fa-check mr-2"></i>Save Changes</b-button>
                    </div>

                    <hr class="mt-6" style="border-bottom: 1px solid #ddd"/>

                    <div class="columns mt-6 is-gapless is-mobile">
                        <div class="column is-narrow">
                            <i class="fal fa-file-signature fa-fw is-size-4 mr-3"></i>
                        </div>
                        <div class="column">
                            <h4 class="mb-5 ml-2">Documents and Loyalty IDs</h4>

                            <div v-if="!documents.length" class="mt-5">
                                <p class="has-text-grey is-italic">You currently don't have any documents or other information on file.</p>
                            </div>
                        </div>
                        <div class="column is-narrow">
                            <b-button type="is-primary" size="is-small" @click="addDocumentModal=true"><i class="fas fa-plus mr-3"></i>New/Upload</b-button>
                        </div>
                    </div>
                </div>

                <document v-for="doc in documents" :document="doc" v-if="documents.length"></document>
                <h5 class="has-text-grey" v-else><i class="fal fa-plane mr-3"></i>You currently have no travel documents.</h5>
            </div>
        </div>
        <b-modal
            v-model="addDocumentModal"
            trap-focus
            :destroy-on-hide="true"
            close-button-aria-label="Close"
            aria-modal>


            <div class="card">
                <div class="card-header">
                    <p class="card-header-title">
                        <i class="fal fa-file-signature fa-fw mr-3"></i>Add a New Document or ID
                    </p>
                </div>

                <div class="card-content">

                    <div class="columns">
                        <div class="column">
                            <b-field label="Subject" label-position="inside">
                                <b-input v-model="newDocument.subject" placeholder="ie. Passport, United Airlines MileagePlus, Delta SkyMiles Number"/>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <b-field label="ID Number" label-position="inside">
                                <b-input v-model="newDocument.id_number"/>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Additional Information" label-position="inside">
                                <b-input type="textarea" v-model="newDocument.information"/>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <file-pond
                                name="file"
                                ref="pond"
                                label-idle="Drop or click to add document..."
                                :allow-multiple="false"
                                :allowRevert="false"
                                :files="files"
                                @init="handleFilePondInit"
                                @processfile="handleFilePondProcess"
                                :server="{
                                url: apiUrl,
                                timeout: 7000,
                                process: {
                                    url: 'app/user/documents/upload',
                                    method: 'POST',
                                    headers: {
                                        'Authorization': token
                                    },
                                    withCredentials: false
                                }
                            }"
                            />

                            <div class="mt-3">
                                <b-tag type="is-info" size="is-small" v-if="newDocument.file"><i class="fas fa-file fa-fw mr-2"></i>{{newDocument.file_name}}</b-tag>
                            </div>
                        </div>
                    </div>
                </div>

                <footer class="card-footer">
                    <div class="card-footer-item">
                        <b-button type="is-primary" @click="saveDoc" :disabled="!newDocument.subject || processing"><i class="fas fa-plus mr-3"></i>Add Document</b-button>
                    </div>
                </footer>
            </div>

        </b-modal>
    </section>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import async from "async";
    import {client as http, url} from "../../http_client";
    import Document from "../../components/Document.vue";

    import vueFilePond from 'vue-filepond';
    import 'filepond/dist/filepond.min.css';
    import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
    import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
    import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

    const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

    export default {
        components: {Document, FilePond},
        data: function() {
            return {
                documents: [],
                user: null,
                addDocumentModal: false,
                newDocument: {
                    file: null,
                },
                processing: false,
                files: [],
                apiUrl: url
            };
        },
        computed: {
            ...mapGetters(['trip', 'registration', 'token']),
        },
        methods: {
            load: function(force) {
                async.series([
                    (cb) => {
                        http.get('/app/user', {force: force}).then(response => {
                            this.user = response.data;
                            cb();
                        });
                    },
                    (cb) => {
                        http.get('/app/user/documents', {force: force}).then(response => {
                            this.documents = response.data;
                            cb();
                        });
                    }
                ]);
            },
            save: function() {
                if (!this.processing) {
                    this.processing = true;

                    let payload = {
                        passport_number: this.user.passport_number,
                        passport_issue_on: this.user.passport_issue_on,
                        passport_expire_on: this.user.passport_expire_on,
                        passport_nationality: this.user.passport_nationality,
                        passport_authority: this.user.passport_authority,
                        traveler_ktn: this.user.traveler_ktn
                    };

                    http.put('/app/user', payload, {force: true}).then(response => {
                        this.$notify({
                            group: 'notify',
                            type: 'success',
                            title: 'Save Complete',
                            text: 'Your information have been saved.'
                        });
                        setTimeout(() => {
                            this.processing = false;
                        }, 3500);
                    });
                }
            },
            saveDoc: function() {
                if (!this.processing) {
                    this.processing = true;

                    http.post('/app/user/documents', this.newDocument, {force: true}).then(response => {
                        this.$notify({
                            group: 'notify',
                            type: 'success',
                            title: 'Document Added',
                            text: 'Your document has been added.'
                        });
                        this.addDocumentModal = false;
                        this.load(true);
                        setTimeout(() => {
                            this.processing = false;
                        }, 3500);
                    });
                }
            },
            handleFilePondInit: function() {
                console.log('FilePond has initialized');
                // FilePond instance methods are available on `this.$refs.pond`
            },

            handleFilePondProcess: function(err, response) {
                this.processing = true;
                // Todo this doesn't handle errors
                // FilePond:processfile - Finished processing a file, if the detail object contains an error property, something went wrong

                let file = JSON.parse(response.serverId);

                this.newDocument.file = file.file_name;
                this.newDocument.file_name = file.file_original_name;
                this.newDocument.file_mime = file.file_mime;

                setTimeout(function() {
                    this.$refs.pond.removeFile(file.id);
                    this.processing = false;
                }.bind(this), 3000);

            },
        },
        watch: {
            registration: function() {
                this.load(true);
            }
        },
        mounted() {
            this.load();
        }
    };
</script>

<style lang="scss" scoped>

</style>
