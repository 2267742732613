<template>
    <div>
        <div id="home-frame">
            <header :style="{'background-image': 'url(' + headerPhoto + ')'}">
                <div class="header-container">
                    <div class="container header-content">
                        <div class="user-bar mobile is-hidden-touch">
                            <div class="columns">
                                <div class="column brand">
                                    <i class="fal fa-compass mr-2"></i> <strong class="has-text-white">Tennis Ventures</strong> Navigator
                                </div>
                                <div class="column user-menu has-text-right">
                                    <span class="mr-5"><strong>{{ welcome }},</strong> {{ (currentUser.preferred_name) ? currentUser.preferred_name : currentUser.first_name }}</span>

                                    <router-link :to="{name: 'Trips'}" class="mr-2 has-text-white"><i class="fas fa-tennis-ball ml-2 mr-1"></i>Choose a Trip</router-link>

                                    <b-tooltip label="Account Settings" position="is-bottom">
                                        <router-link :to="{name: 'Settings'}"><i class="fas fa-user-cog ml-2 has-text-white"></i></router-link>
                                    </b-tooltip>

                                    <b-tooltip label="Logout" position="is-bottom">
                                        <i class="fas fa-sign-out ml-2" @click="logout"></i>
                                    </b-tooltip>
                                </div>
                            </div>
                        </div>
                        <div class="user-bar is-hidden-desktop">
                            <div class="is-pulled-left brand" @click="sideOpen = !sideOpen">
                                <i class="fas fa-bars mr-2"></i>Menu
                            </div>
                            <div class="is-pulled-right user-menu has-text-right">
                                {{ currentUser.first_name }} {{ currentUser.last_name }}
                                <i class="fas fa-sign-out ml-3" @click="logout"></i>
                            </div>
                            <div class="is-clearfix"></div>
                        </div>
                        <div class="trip-header">
                            <router-link :to="{name: 'Trips'}" v-if="this.registrations.length > 1"><h2>{{ trip.title }}</h2></router-link>
                            <h2 v-else>{{ trip.title }}</h2>
                            <h5 v-if="trip.title">{{ tripDates }}</h5>
                        </div>
                        <div class="full-menu is-hidden-touch">
                            <ul>
                                <router-link :to="{name: 'Today'}">
                                    <li class="active"><i class="fal fa-calendar-day"></i>Today</li>
                                </router-link>
                                <router-link :to="{name: 'Trip'}">
                                    <li><i class="fal fa-tennis-ball"></i>Itinerary & Trip Info</li>
                                </router-link>
                                <router-link :to="{name: 'Travelers'}">
                                    <li><i class="fal fa-users"></i>Fellow Travelers</li>
                                </router-link>
                                <router-link :to="{name: 'Experience'}">
                                    <li><i class="fal fa-hashtag"></i>Experience</li>
                                </router-link>
                                <router-link :to="{name: 'Tools'}">
                                    <li><i class="fal fa-user-cog"></i>Account</li>
                                </router-link>
                            </ul>
                        </div>
                    </div>

                </div>

            </header>

            <main class="container">
                <router-view/>
            </main>

            <footer class="container has-text-centered is-hidden-touch">
                <hr/>
                <div class="columns mt-3">
                    <div class="column"></div>
                    <div class="has-text-centered">
                        <a href="https://www.tennisventures.net"><img src="@/assets/logo.svg"/></a>
                    </div>
                    <div class="column"></div>
                </div>
            </footer>
        </div>
        <div class="sidebar-page">
            <section class="sidebar-layout">
                <b-sidebar :delay="500" type="is-light" fullheight overlay v-model="sideOpen">
                    <div class="has-text-centered pt-5"><img src="@/assets/logo.svg" class="side-logo"/></div>

                    <b-menu class="side-menu ml-2 mr-2 mt-4">
                        <b-menu-list class="mb-6">
                            <b-menu-item icon-pack="far" icon="calendar-day" label="Today" :tag="'router-link'" :to="{name: 'Today'}" @click.native="sideOpen=false"/>

                            <b-menu-item icon-pack="far" icon="tennis-ball" label="Itinerary & Trip Info" :expanded="$route.path.indexOf('trip') !== -1">
                                <b-menu-item icon-pack="fal" icon="calendar-alt" label="Itinerary" :tag="'router-link'" :to="{name: 'Itinerary'}" @click.native="sideOpen=false"/>
                                <b-menu-item icon-pack="fal" icon="plane" label="Flights" :tag="'router-link'" :to="{name: 'Flights'}" @click.native="sideOpen=false"/>
                                <b-menu-item icon-pack="fal" icon="tasks" label="Trip Preferences" :tag="'router-link'" :to="{name: 'TripPreferences'}" @click.native="sideOpen=false"/>
                                <b-menu-item icon-pack="fal" icon="passport" label="Travel Requirements" :tag="'router-link'" :to="{name: 'Travel'}" @click.native="sideOpen=false"/>
                                <b-menu-item icon-pack="fal" icon="suitcase-rolling" label="Packing List" :tag="'router-link'" :to="{name: 'Packing'}" @click.native="sideOpen=false"/>
                                <b-menu-item icon-pack="fal" icon="question-square" label="FAQs" :tag="'router-link'" :to="{name: 'FAQs'}" @click.native="sideOpen=false"/>
                                <b-menu-item icon-pack="fal" icon="city" label="Cities" :tag="'router-link'" :to="{name: 'Cities'}" @click.native="sideOpen=false"/>
                                <b-menu-item icon-pack="fal" icon="trophy" label="Tournament" :tag="'router-link'" :to="{name: 'Tournament'}" @click.native="sideOpen=false"/>

                                <b-menu-item icon-pack="fal" icon="tennis-ball" label="Coach Information" :tag="'router-link'" :to="{name: 'Coach'}" v-if="registration.type === 'coach' || registration.type === 'staff'" @click.native="sideOpen=false"/>
                                <b-menu-item icon-pack="fal" icon="user-tie" label="Staff Information" :tag="'router-link'" :to="{name: 'Staff'}" v-if="registration.type === 'staff'" @click.native="sideOpen=false"/>
                            </b-menu-item>
                            
                            <b-menu-item icon-pack="fal" icon="plane" label="Flights" :tag="'router-link'" :to="{name: 'Flights'}" @click.native="sideOpen=false"/>

                            <b-menu-item icon-pack="far" icon="users" label="Fellow Travelers" :tag="'router-link'" :to="{name: 'Travelers'}" @click.native="sideOpen=false"/>

                            <b-menu-item icon-pack="far" icon="hashtag" label="Experience" :tag="'router-link'" :to="{name: 'Experience'}" @click.native="sideOpen=false"/>

                            <b-menu-item icon-pack="far" icon="user-cog" label="Account" :expanded="$route.path.indexOf('tools') !== -1">
                                <b-menu-item icon-pack="fal" icon="credit-card-front" label="Trip Cost" :tag="'router-link'" :to="{name: 'Billing'}" @click.native="sideOpen=false"/>
                                <b-menu-item icon-pack="fal" icon="tasks" label="Trip Preferences" :tag="'router-link'" :to="{name: 'ToolsPreferences'}" @click.native="sideOpen=false"/>
                                <b-menu-item icon-pack="fal" icon="file-passprt" label="Documents & IDs" :tag="'router-link'" :to="{name: 'Documents'}" @click.native="sideOpen=false"/>
                                <b-menu-item icon-pack="far" icon="user-cog" label="Account Settings" :tag="'router-link'" :to="{name: 'Settings'}" @click.native="sideOpen=false"/>
                                <b-menu-item icon-pack="far" icon="address-card" label="Profile & Photo" :tag="'router-link'" :to="{name: 'Profile'}" @click.native="sideOpen=false"/>
                                <b-menu-item icon-pack="far" icon="credit-card" label="Payment Method" :tag="'router-link'" :to="{name: 'Payment'}" @click.native="sideOpen=false"/>
                            </b-menu-item>
                        </b-menu-list>

                        <section class="mt-5">
                            <b-menu-list :label="currentUser.first_name + ' ' + currentUser.last_name">
                                <b-menu-item icon-pack="far" icon="tennis-ball" label="Choose Trip" v-if="this.registrations.length > 1" :tag="'router-link'" :to="{name: 'Trips'}" @click.native="sideOpen=false"/>
                                <b-menu-item icon-pack="far" icon="sign-out" label="Logout" @click="logout"/>
                                <b-menu-item icon-pack="far" icon="arrows-rotate" label="Refresh" @click="refresh"/>
                            </b-menu-list>
                        </section>

                    </b-menu>
                </b-sidebar>
            </section>
        </div>
        <notifications group="notify" />
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    import {client as http} from "../http_client";
    import async from "async";
    import moment from "moment";

    export default {
        name: 'Home',
        components: {},
        data: function() {
            return {
                sideOpen: false,
                registrations: [],
                photos: [],
                headerPhoto: '',

                deferredPrompt: null
            };
        },
        computed: {
            ...mapGetters(['currentUser', 'trip', 'registration']),
            welcome: function() {
                const currentHour = new Date().getHours();
                return currentHour >= 4 && currentHour < 12 ? 'Good morning' :
                    currentHour >= 12 && currentHour <= 17 ? 'Good afternoon' :
                        currentHour > 17 || currentHour < 4 ? 'Good evening' : 'Welcome';
            },
            tripDates: function() {
                let s = moment(this.trip.start_on);
                let e = moment(this.trip.end_on);
                if (s.isSame(e, 'day')) {
                    return s.format('MMMM Do, YYYY');
                } else if (s.isSame(e, 'month')) {
                    return s.format('MMMM Do') + ' - ' + e.format('Do, YYYY');
                } else if (s.isSame(e, 'year')) {
                    return s.format('MMMM Do') + ' - ' + e.format('MMMM Do, YYYY');
                } else {
                    return s.format('MMMM Do, YYYYY') + ' - ' + e.format('MMMM Do, YYYY');
                }
            }
        },
        methods: {
            ...mapActions(['signOut', 'loadRegistration', 'loadTrip']),
            logout() {
                this.$router.push({name: 'Login'});
                this.signOut();
            },
            refresh() {
                window.location = 'index.html?cache=' + Date.now();
            },
            loadRegistrations: function(force) {
                let regId, reg;
                async.series([
                    (cb) => {
                        http.get('/app/registrations/', {force: true}).then(response => {
                            this.registrations = response.data;
                            cb();
                        });
                    },
                    (cb) => {
                        reg = getDefaultRegistration(this.registrations);
                        this.loadRegistration(reg.id).then(response => {
                            cb();
                        });
                    },
                    (cb) => {
                        this.loadTrip(reg.trip_id).then(response => {
                            cb();
                        });
                    }
                ], err => {

                });
            }
        },
        watch: {
            'trip': function() {
                http.get('/app/trips/' + this.trip.id + '/photos/0/30', {force: true}).then(response => {
                    this.photos = response.data.photos;
                    this.headerPhoto = 'https://app.tennisventures.net:3000/api/trip_photos/img/' + this.photos[0].file + '/trip';
                });
            }
        },
        mounted() {
            this.loadRegistrations(true);
        }
    };

    function getDefaultRegistration(registrations) {
        // Get the stored registration ID from local storage
        const storedRegId = localStorage.getItem('registration');


        // Try to find the registration in the array
        if (storedRegId) {
            const storedRegistration = registrations.find(reg => reg.id === parseInt(storedRegId));
            if (storedRegistration) {
                return storedRegistration;
            }
        }

        const now = new Date();

        // Filter out registrations that have already ended
        const validRegistrations = registrations.filter(reg => {
            const endOn = new Date(reg.trip.end_on);
            return endOn >= now;
        });

        // Find an active registration (trip is ongoing)
        const activeRegistration = validRegistrations.find(reg => {
            const startOn = new Date(reg.trip.start_on);
            const endOn = new Date(reg.trip.end_on);
            return startOn <= now && endOn >= now;
        });

        if (activeRegistration) {
            return activeRegistration;
        }

        // Find the first upcoming registration
        const upcomingRegistration = validRegistrations.sort((a, b) => {
            const startA = new Date(a.trip.start_on);
            const startB = new Date(b.trip.start_on);
            return startA - startB;
        })[0];

        return upcomingRegistration || null; // Return null if no valid registrations found
    }
</script>

<style lang="scss">
    #home-frame {
        header {
            background: #252722 radial-gradient(#32322e, #252722);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;

            .header-container {
                background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5));
                padding-bottom: 2rem;

                .header-content {
                    padding: 0 0.75rem;
                }

                @media only screen and (max-width: 1023px) {
                    padding-bottom: 1rem;
                }

                @media only screen and (max-width: 600px) {
                    padding-bottom: 0;
                }

                .user-bar {
                    background-color: rgba(0, 0, 0, 0.4);;

                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;

                    box-shadow: 5px 5px 30px 0 rgba(0, 0, 0, 0.4);
                    padding: 0.5rem 1rem;
                    color: white;
                    font-size: 1rem;

                    @media only screen and (max-width: 600px) {
                        padding: 0 1rem;
                    }

                    .brand {
                        font-size: 1.1rem;
                        padding-top: 1.3rem;

                        i.fa-compass {
                            font-size: 2.2rem;
                            vertical-align: middle;
                        }

                        @media only screen and (max-width: 600px) {
                            padding-top: 0.6rem;
                        }
                    }

                    .user-menu {
                        strong {
                            color: white;
                        }

                        padding-top: 1.55rem;

                        @media only screen and (max-width: 600px) {
                            padding-top: 0.75rem;
                        }

                        i {
                            cursor: pointer;
                            padding: 0.3rem;
                        }
                    }

                }

                .trip-header {
                    margin: 2rem 0 1rem;
                    padding: 4rem 1rem 1rem;

                    @media only screen and (max-width: 1023px) {
                        margin: 0;
                        padding: 1rem;
                    }

                    @media only screen and (max-width: 600px) {
                        margin: 0;
                        padding: 1rem;
                    }

                    h2 {
                        color: white;
                        font-size: 3rem;
                        margin: 0;

                        @media only screen and (max-width: 1407px) {
                            font-size: 2.5rem;
                        }

                        @media only screen and (max-width: 1215px) {
                            font-weight: bold;
                            font-size: 1.8rem;
                        }

                        @media only screen and (max-width: 600px) {
                            font-weight: bold;
                            font-size: 1.2rem;
                        }
                    }

                    h5 {
                        color: white;
                    }


                }

                .full-menu {
                    background-color: rgba(255, 255, 255, 0.9);
                    border-radius: 5px;

                    box-shadow: 5px 5px 30px 0 rgba(0, 0, 0, 0.6);
                    padding: 0.5rem;


                    ul {
                        padding: 0;
                        margin: 0;
                        list-style: none;
                        display: flex;
                        justify-content: space-between;

                        a {
                            li {
                                cursor: pointer;
                                color: #183a01;
                                font-size: 1.1rem;
                                font-weight: bold;
                                padding: 0.6rem 3.5rem 0.5rem;
                                border-radius: 5px;

                                i {
                                    color: #628b21;

                                    font-size: 1.8rem;
                                    vertical-align: middle;
                                    margin-right: 1rem;
                                }

                                &:hover {
                                    background: white;
                                }


                                @media only screen and (max-width: 1407px) {
                                    padding: 0.3rem 2.5rem;
                                    font-size: 1rem;

                                    i {
                                        font-size: 1.4rem;
                                    }
                                }

                                @media only screen and (max-width: 1215px) {
                                    padding: 0.3rem 2rem;
                                    font-size: 0.95rem;

                                    i {
                                        font-size: 1.2rem;
                                    }
                                }
                            }

                            &.router-link-active li {
                                background: white;
                            }
                        }

                    }
                }
            }
        }

        main {
            padding: 4rem 1rem 2rem;

            @media only screen and (max-width: 1407px) {
                padding: 2.5rem 1rem 2rem;
            }

            @media only screen and (max-width: 1215px) {
                padding: 2rem 1rem 2rem;
            }

            @media only screen and (max-width: 1023px) {
                padding: 1rem 1rem 2rem;
            }

            .sub-nav-menu {
                margin: 0;
                padding: 0;
                list-style: none;
                border-radius: 0.5rem;

                a {
                    color: #333;


                    i {
                        color: #628b21;
                    }

                    &:first-child li {
                        border-top-left-radius: 0.5rem;
                        border-top-right-radius: 0.5rem;
                    }

                    &:last-child li {
                        border-bottom-left-radius: 0.5rem;
                        border-bottom-right-radius: 0.5rem;
                    }

                    &.router-link-exact-active li {
                        background: #5b7526;
                        color: white;

                        i {
                            color: white;
                        }
                    }
                }

                li {
                    font-size: 1rem;
                    font-weight: bold;
                    padding: 0.7rem 1.2rem;
                    border: 1px solid #f4f4f4;

                    background: white;

                    &:hover {
                        background: #fafafa;
                    }

                }
            }

            .white-container {
                background-color: white;
                border-radius: 0.5rem;
                padding: 2rem;

                @media only screen and (max-width: 1023px) {
                    padding: 1rem;
                }
            }
        }

        footer {
            margin-top: 6rem;
            margin-bottom: 4rem;

            img {
                width: 100%;
                max-width: 100px;
            }

            hr {
                background-color: #ebebeb;
            }
        }
    }
</style>

<style lang="scss">
    .side-logo {
        width: 125px;
    }

    .side-menu {
        a {
            margin-bottom: 4px;

            &.router-link-exact-active {
                background-color: #93af66;
                color: white;
                font-weight: bold;
                border-radius: 3px;
            }
        }
    }

    .vue-notification {
        font-size: 1.3rem !important;

        &.success {
            background: #a2b977 !important;
            border-left-color: #5b7526 !important;
        }

        .notification-content {
            margin-top: 10px;
            font-size: 1.1rem !important;
            line-height: 1.1rem;
        }
    }
</style>
