<template>
    <div class="columns is-multiline is-mobile flight-container" :class="{'print': print}">
        <div class="column is-narrow is-hidden-mobile">
            <date-label :date="flight.depart_at" :tz="flight.depart_airport.tz_database" :print="print"/>
        </div>
        <div class="column content">
            <div class="has-text-weight-bold">{{flight.depart_airport.name}} ({{flight.depart_airport.iata}})</div>
            <div class="meta">{{departLocal}} - {{flight.depart_airport.city}}, {{flight.depart_airport.country}}</div>

            <div class="mt-2 mb-2 is-size-7"><i class="far fa-ellipsis-h"></i><i class="fas fa-plane ml-2 mr-2"></i><b-tag>{{duration}}</b-tag></div>

            <div class="has-text-weight-bold">{{flight.arrive_airport.name}} ({{flight.arrive_airport.iata}})</div>
            <div class="meta">{{arriveLocal}} - {{flight.arrive_airport.city}}, {{flight.arrive_airport.country}}</div>

            <div class="data mt-3">
                <div class="meta" v-if="flight.registration && flight.registration.user && flight.applies_to === 'primary'"><i class="far fa-user fa-fw mr-2"></i><strong>Passenger</strong>&nbsp; {{flight.registration.user.last_name}}, {{flight.registration.user.first_name}}</div>
                <div class="meta" v-else-if="flight.registration && flight.registration.second_user && flight.applies_to === 'secondary'"><i class="far fa-user-user fa-fw mr-2"></i><strong>Passenger</strong>&nbsp; {{flight.registration.second_user.last_name}}, {{flight.registration.second_user.first_name}}</div>
                <div class="meta" v-else-if="flight.applies_to === 'both'"><i class="far fa-user-group fa-fw mr-2"></i><strong>Passengers</strong>&nbsp;
                    <span>{{flight.registration.user.first_name}} {{flight.registration.user.last_name}}</span>,
                    <span>{{flight.registration.second_user.first_name}} {{flight.registration.second_user.last_name}} </span>
                </div>
                <div class="meta"><i class="far fa-plane-departure fa-fw mr-2"></i><strong>Operator</strong>&nbsp; {{flight.operator}}</div>
                <div class="meta"><i class="far fa-input-numeric fa-fw mr-2"></i><strong>Flight #</strong>&nbsp; {{flight.flight_number}}</div>
                <div class="meta" v-if="flight.reference_number"><i class="far fa-ticket-airline fa-fw mr-2"></i><strong>Reference #</strong>&nbsp; {{flight.reference_number}}</div>
                <div class="meta" v-if="flight.travel_class"><i class="far fa-layer-group fa-fw mr-2"></i><strong>Class</strong>&nbsp; {{flight.travel_class | capitalize}}</div>
                <div class="meta" v-if="flight.seat"><i class="far fa-seat-airline fa-fw mr-2"></i><strong>Seat</strong>&nbsp; {{flight.seat | uppercase}}</div>
            </div>

            <div class="mt-3 mb-3 notes" v-if="flight.flight_notes">
                {{flight.flight_notes}}
            </div>
        </div>
    </div>
</template>

<script>

    import moment from "moment-timezone";
    import DateLabel from "./DateLabel";

    export default {
        name: 'Flight',
        components: {DateLabel},
        props: {
            flight: {
                type: Object,
                required: true
            },
            print: {
                type: Boolean,
                default: false
            }
        },
        data: function () {
            return {
            };
        },
        computed: {
            duration: function () {
                if (this.flight.arrive_at && this.flight.depart_at) {
                    let min = moment.duration(moment(this.flight.arrive_at).diff(this.flight.depart_at)).asMinutes();
                    let hours = Math.floor(min / 60);
                    let minutes = min % 60;
                    if (hours > 0) {
                        return hours + ' hour' + (hours !== 1 ? 's' : '') + ', ' + minutes + ' minute' + (minutes !== 1 ? 's' : '');
                    } else {
                        return minutes + ' minutes';
                    }
                }
            },
            departLocal: function () {
                if (this.flight.depart_at)
                    return moment.utc(this.flight.depart_at).tz(this.flight.depart_airport.tz_database).format('MMM Do, YYYY, h:mm A');
            },
            arriveLocal: function () {
                if (this.flight.arrive_at)
                    return moment.utc(this.flight.arrive_at).tz(this.flight.arrive_airport.tz_database).format('MMM Do, YYYY, h:mm A');
            },
            departLocalTime: function () {
                if (this.flight.depart_at)
                    return moment.utc(this.flight.depart_at).tz(this.flight.depart_airport.tz_database).format('h:mm A');
            },
            arriveLocalTime: function () {
                if (this.flight.arrive_at)
                    return moment.utc(this.flight.arrive_at).tz(this.flight.arrive_airport.tz_database).format('h:mm A');
            }
        },
        mounted() {
            console.log(this.departLocalDate);
        }
    };
</script>

<style lang="scss" scoped>
    .flight-container {
        margin: 0.05px 0.05px 1rem;
        background-color: white;
        border-radius: 0.3rem;
        position: relative;
        padding: 0.05px;

        &.details {
            margin-bottom: 3rem;
        }

        @media only screen and (max-width: 768px) {
            h4 {
                font-size: 1rem;
            }
        }

        .meta {
            font-size: 0.85rem;
            color: #4a4a4a;
        }

        .notes {
            border: 1px solid #d9d9d9;
            border-radius: 8px;
            padding: 1rem 1.2rem;
            font-size: 0.9rem;
        }
    }

</style>
