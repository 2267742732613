<template>
    <div class="columns">
        <div class="column is-one-quarter-fullhd is-one-third-widescreen is-one-third-tablet is-hidden-touch">
            <ul class="sub-nav-menu">
                <router-link :to="{name: 'Billing'}">
                    <li><i class="far fa-credit-card-front fa-fw mr-3"></i>Trip <span class="is-hidden-desktop-only">Cost </span>& Payments</li>
                </router-link>
                <router-link :to="{name: 'ToolsPreferences'}">
                    <li><i class="far fa-tasks fa-fw mr-3"></i>Trip Preferences</li>
                </router-link>
                <router-link :to="{name: 'Documents'}">
                    <li><i class="far fa-passport fa-fw mr-3"></i>Documents & IDs</li>
                </router-link>
            </ul>
            <ul class="sub-nav-menu mt-4">
                <router-link :to="{name: 'Settings'}">
                    <li><i class="far fa-user-cog fa-fw mr-3"></i>Account Settings</li>
                </router-link>
                <router-link :to="{name: 'Profile'}">
                    <li><i class="far fa-address-card fa-fw mr-3"></i>Profile & Photo</li>
                </router-link>
                <router-link :to="{name: 'Payment'}">
                    <li><i class="far fa-credit-card fa-fw mr-3"></i>Payment Method</li>
                </router-link>
            </ul>
            <ul class="sub-nav-menu mt-4" v-if="currentUser.registrations > 1">
                <router-link :to="{name: 'Trips'}">
                    <li><i class="far fa-tennis-ball fa-fw mr-3"></i>Choose a <span class="is-hidden-desktop-only">Different </span>Trip</li>
                </router-link>
            </ul>
        </div>
        <div class="column is-offset-1-fullhd ">
            <router-view/>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        computed: {
            ...mapGetters(['currentUser', 'trip', 'registration']),
        },
    };
</script>

<style lang="scss" scoped>

</style>
