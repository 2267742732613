<template>
    <div class="date-container" :class="{'print': print}">
        <div class="month">{{ formattedDate | moment('MMM') }}</div>
        <div class="day">{{ formattedDate | moment('DD') }}</div>
        <div class="dow">{{ formattedDate | moment('ddd') }}</div>
    </div>
</template>

<script>
    import moment from "moment-timezone";

    export default {
        name: "DateLabel",
        props: {
            date: {
                type: [String, Date], // Accepts both strings and Date objects
                required: true
            },
            print: {
                type: Boolean,
                default: false
            },
            tz: {
                type: String, // Timezone string, e.g., "America/New_York"
                default: null
            }
        },
        computed: {
            formattedDate() {
                // Handle the date formatting with timezone if provided
                if (this.tz) {
                    return moment.tz(this.date, this.tz); // Converts to Date in the specified timezone
                }
                return moment(this.date).toDate(); // Default fallback
            }
        }
    };
</script>

<style lang="scss" scoped>
    .date-container {
        padding: 0.5rem 1rem 0.5rem 1rem;
        background-color: #f4f4f4;
        border-radius: 0.3rem;
        text-align: center;
        display: inline-block;
        line-height: 1.4rem;
        font-family: "DM Mono", monospace;

        &.print {
            padding: 0.5rem 0.5rem 0.2rem 0.5rem;

            border: 1px solid #5b5b5b;
            background-color: #ffffff;
        }

        .dow {
            font-weight: 300;
            font-style: normal;
            font-size: 1.2rem;

            text-transform: uppercase;
            margin-left: 0.1rem;
            margin-top: 0.4rem;
            color: #888888;
        }

        .day {
            font-weight: bold;
            font-size: 1.8rem;

            //margin-bottom: 1.4rem;
        }

        .month {
            font-weight: 300;
            font-style: normal;
            font-size: 1.2rem;

            text-transform: uppercase;
            margin-left: 0.1rem;
            color: #888888;
        }
    }
</style>
