<template>
    <div class="content">
        <h3 class="mt-2 mb-5 ml-2 is-hidden-desktop"><i class="fal fa-tasks fa-fw mr-3"></i>Trip Preferences</h3>

        <div class="content mt-6 mb-6" v-if="!questions">
            <h5 class="has-text-grey"><i class="fal fa-tennis-ball fa-spin mr-3"></i>Loading your preferences. Please wait...</h5>
        </div>

        <div v-else class="white-container">
            <div class="mb-6" v-if="!trip.questions_enabled"><i class="far fa-info-circle mr-3"></i>Trip Preferences are currently locked for this trip.</div>
            <div v-for="q in questions" class="mb-5">
                <b-field :label="q.question" :message="q.subtext" label-position="inside">
                    <b-input v-if="q.type === 'text'" v-model="q.answer" :disabled="!trip.questions_enabled"/>
                    <b-input type="textarea" v-if="q.type === 'multi'" v-model="q.answer" :disabled="!trip.questions_enabled"/>
                    <b-select v-else-if="q.type === 'single'" v-model="q.answer" expanded :disabled="!trip.questions_enabled">
                        <option :value="o.id" v-for="o in q.options">{{ o.answer }}</option>
                    </b-select>
                </b-field>
            </div>
        </div>

        <div class="mt-6 has-text-right">
            <b-button type="is-primary" @click="save" :disabled="!trip.questions_enabled || processing"><i class="fas fa-check mr-2"></i>Save Changes</b-button>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import async from "async";
    import {client as http} from "../http_client";

    export default {
        data: function() {
            return {
                questions: null,
                processing: false
            };
        },
        computed: {
            ...mapGetters(['trip', 'registration']),
        },
        methods: {
            load: function(force) {
                if (this.registration.id) {
                    http.get('/app/registrations/' + this.registration.id + '/questions', {force: true}).then(response => {
                        this.questions = response.data;
                    });
                }
            },
            save: function() {
                if (!this.processing) {
                    this.processing = true;

                    let payload = [];
                    for (let i = 0; i < this.questions.length; i++) {
                        const question = this.questions[i];
                        let q = {
                            question_id: question.id,
                            registration_id: this.registration.id
                        };

                        if (question.type === 'single') {
                            for (let j = 0; j < question.options.length; j++) {
                                const option = question.options[j];
                                if (option.id === question.answer) {
                                    q.answer_id = option.id;
                                    q.response = option.answer;
                                    break;
                                }
                            }
                        } else {
                            q.response = question.answer;
                        }

                        payload.push(q);
                    }

                    http.post('/app/registrations/' + this.registration.id + '/responses', payload, {force: true}).then(response => {
                        this.$notify({
                            group: 'notify',
                            type: 'success',
                            title: 'Save Complete',
                            text: 'Your trip preferences have been saved.'
                        });
                        setTimeout(() => {
                            this.processing = false;
                        }, 3500);
                    });
                }
            }
        },
        watch: {
            registration: function() {
                this.load(true);
            }
        },
        mounted() {
            this.load(true);
        }
    };

    function getAnswerById(questions, id) {
        for (let i = 0; i < questions.length; i++) {
            const question = questions[i];

        }
    }
</script>

<style lang="scss" scoped>

</style>
