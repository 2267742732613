<template>
    <div>
        <trip-preferences/>
    </div>
</template>

<script>

    import TripPreferences from "../../components/TripPreferences.vue";

    export default {
        components: {TripPreferences},
        data: function() {

        }
    };
</script>

<style lang="scss" scoped>

</style>
