<template>
    <div class="container content">
        <div class="mt-6 pt-6 has-text-centered">
            <a href="https://www.tennisventures.net"><img src="@/assets/logo.svg"/></a>
            <h3 class="has-text-grey mt-6 pt-3">
                <i class="fas fa-spinner fa-spin mr-3"></i>Please wait. Getting your details...
            </h3>
        </div>
    </div>
</template>

<script>
    import {client as http, axiosInstance} from '../http_client';
    import {mapActions, mapGetters} from "vuex";

    export default {
        props: ['keyToken', 'regId', 'returnTo'],
        data: function() {
            return {};
        },
        computed: {
            ...mapGetters(['currentUser', 'token'])
        },
        methods: {
            ...mapActions(['loadRegistration']),
            getAuth: function() {
                http.post('/login/share/', {key: this.keyToken}, {force: true}).then(response => {
                    this.$store.commit('setUser', {token: this.keyToken, ...response.data});

                    localStorage.setItem('token', this.keyToken);
                    localStorage.setItem('user', JSON.stringify(response.data));

                    console.log('Setting Authorization Header', this.keyToken);
                    axiosInstance.defaults.headers.common['Authorization'] = this.keyToken;

                    this.loadRegistration(this.regId).then(() => {
                        // Do redirect
                        if (this.returnTo === 'TravelDetails') {
                            this.$router.push({name: 'TravelDetails', params: {id: this.regId}});
                        } else {
                            this.$router.push({name: this.returnTo});
                        }
                    });
                });
            }
        },
        mounted: function() {
            this.getAuth();
        }
    };
</script>

<style scoped>
    img {
        max-width: 200px;
    }
</style>
