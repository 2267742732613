<template>
    <section class="content">
        <h3 class="mt-2 mb-5 ml-2 is-hidden-desktop"><i class="fal fa-plane fa-fw mr-3"></i>Flights</h3>

        <div class="content mt-6 mb-6" v-if="!flights">
            <h5 class="has-text-grey"><i class="fal fa-tennis-ball fa-spin mr-3"></i>Loading your flights. Please wait...</h5>
        </div>
        <div v-else>
            <section v-if="tvArranged.length">
                <flight :flight="flight" v-for="flight in tvArranged"></flight>
            </section>
            <h5 class="has-text-grey" v-else><i class="fal fa-plane mr-3"></i>You currently have no arranged flights. Check back later.</h5>

            <hr class="mt-6" style="border-bottom: 1px solid #ddd"/>

            <div class="columns mt-6 is-gapless is-mobile">
                <div class="column is-narrow">
                    <i class="fal fa-plane fa-fw is-size-4 mr-3"></i>
                </div>
                <div class="column">
                    <h4>Your Arranged Flights</h4>

                    <div v-if="selfArranged.length === 0" class="mt-5">
                        <p class="has-text-grey is-italic">You have not added any flights that you have arranged yourself. This is optional, however, it will add it to your personal Trip Itinerary if added.</p>
                    </div>
                </div>
                <div class="column is-narrow ml-5">
                    <b-button type="is-primary" size="is-small" @click="addFlightModal=true"><i class="fas fa-plus mr-3"></i>New Flight</b-button>
                </div>
            </div>

            <section v-if="selfArranged.length">
                <flight :flight="flight" v-for="flight in selfArranged"></flight>
            </section>

        </div>

        <b-modal
            v-model="addFlightModal"
            trap-focus
            :destroy-on-hide="true"
            close-button-aria-label="Close"
            aria-modal>

            <div class="card">
                <div class="card-header">
                    <p class="card-header-title">
                        <i class="fal fa-plane fa-fw mr-3"></i>Add a Flight You Arranged
                    </p>
                </div>

                <div class="card-content">

                    <div class="columns">
                        <div class="column">
                            <b-field label="Applies To" label-position="inside">
                                <b-select expanded v-model="newFlight.applies_to">
                                    <option value="primary">{{ registration.user.last_name }}, {{ registration.user.first_name }} (Primary)</option>
                                    <option value="secondary" v-if="registration.second_user">{{ registration.second_user.last_name }}, {{ registration.second_user.first_name }} (Secondary)</option>
                                    <option value="both" v-if="registration.second_user">Both Participants</option>
                                </b-select>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Operator" label-position="inside">
                                <b-input v-model="newFlight.operator"></b-input>
                            </b-field>
                        </div>
                        <div class="column is-3">
                            <b-field label="Flight Number" label-position="inside">
                                <b-input v-model="newFlight.flight_number"></b-input>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field label="Reference Number" label-position="inside">
                                <b-input v-model="newFlight.reference_number"></b-input>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Class" label-position="inside">
                                <b-select expanded v-model="newFlight.travel_class">
                                    <option value="first">First</option>
                                    <option value="business">Business</option>
                                    <option value="premium-economy">Premium Economy</option>
                                    <option value="economy">Economy</option>
                                </b-select>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field label="Seat" label-position="inside">
                                <b-input v-model="newFlight.seat"/>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Additional Flight Notes" label-position="inside">
                                <b-input type="textarea" v-model="newFlight.flight_notes"></b-input>
                            </b-field>
                        </div>
                    </div>
                    <hr/>

                    <div class="columns">
                        <div class="column">
                            <div v-if="!newFlight.depart_airport">
                                <b-field label="Departure Airport" label-position="inside">
                                    <b-autocomplete
                                        :data="airportResults"
                                        field="title"
                                        :loading="isFetching"
                                        @typing="searchAirports"
                                        @select="selectDepart"
                                        icon="search"
                                        placeholder="Search Airports...">

                                        <template slot-scope="props">
                                            <i class="fas fa-map-marker-alt mr-2"></i>{{ props.option.name }} ({{ props.option.iata }})
                                            <br>
                                            <small>
                                                {{ props.option.city }}, {{ props.option.country }}
                                            </small>
                                        </template>
                                        <template slot="empty">No results.</template>
                                    </b-autocomplete>
                                </b-field>
                            </div>
                            <div v-else>
                                <div class="columns">
                                    <div class="column">
                                        <div class="is-size-7 has-text-weight-bold">Departure Airport</div>
                                        <div class="mt-3"><i class="fas fa-map-marker-alt mr-2"></i>{{ newFlight.depart_airport.name }} ({{ newFlight.depart_airport.iata }})</div>
                                        <div class="is-size-7">{{ newFlight.depart_airport.city }}, {{ newFlight.depart_airport.country }}</div>
                                    </div>
                                    <div class="column has-text-right">
                                        <b-button size="is-small" outlined @click="clearDepart()"><i class="fal fa-times mr-2"></i>Remove</b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Departure Date" label-position="inside">
                                <b-input type="date" v-model="newFlight.depart_date"/>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field label="Departure Time" label-position="inside">
                                <b-input type="time" v-model="newFlight.depart_time"/>
                            </b-field>
                        </div>
                    </div>
                    <hr/>
                    <div class="columns">
                        <div class="column">
                            <div v-if="!newFlight.arrive_airport">
                                <b-field label="Arrival Airport" label-position="inside">
                                    <b-autocomplete
                                        :data="airportResults"
                                        field="title"
                                        :loading="isFetching"
                                        @typing="searchAirports"
                                        @select="selectArrive"
                                        icon="search"
                                        placeholder="Search Airports...">

                                        <template slot-scope="props">
                                            <i class="far fa-map-marker-alt mr-2"></i>{{ props.option.name }} ({{ props.option.iata }})
                                            <br>
                                            <small>
                                                {{ props.option.city }}, {{ props.option.country }}
                                            </small>
                                        </template>
                                        <template slot="empty">No results.</template>
                                    </b-autocomplete>
                                </b-field>
                            </div>
                            <div v-else>
                                <div class="columns">
                                    <div class="column">
                                        <div class="is-size-7 has-text-weight-bold">Arrival Airport</div>
                                        <div class="mt-3"><i class="fas fa-map-marker-alt mr-2"></i>{{ newFlight.arrive_airport.name }} ({{ newFlight.arrive_airport.iata }})</div>
                                        <div class="is-size-7">{{ newFlight.arrive_airport.city }}, {{ newFlight.arrive_airport.country }}</div>
                                    </div>
                                    <div class="column has-text-right">
                                        <b-button size="is-small" outlined @click="clearArrive()"><i class="fal fa-times mr-2"></i>Remove</b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Arrival Date" label-position="inside">
                                <b-input type="date" v-model="newFlight.arrive_date"/>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field label="Arrival Time" label-position="inside">
                                <b-input type="time" v-model="newFlight.arrive_time"/>
                            </b-field>
                        </div>
                    </div>
                </div>

                <footer class="card-footer">
                    <div class="card-footer-item">
                        <b-button type="is-primary" @click="createFlight" :disabled="!newFlight.depart_airport || !newFlight.arrive_airport || !newFlight.depart_date || !newFlight.depart_time || !newFlight.arrive_date || !newFlight.arrive_time || processing"><i class="fas fa-plus mr-3"></i>Add Flight</b-button>
                    </div>
                </footer>
            </div>

        </b-modal>
    </section>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import async from "async";
    import {client as http} from "../../http_client";
    import Flight from "../../components/Flight";
    import debounce from "debounce";

    export default {
        components: {Flight},
        data: function() {
            return {
                flights: null,
                addFlightModal: false,
                newFlight: {
                    depart_airport: null,
                    depart_airport_id: null,
                    arrive_airport: null,
                    arrive_airport_id: null,
                    arranged_by: 'self',
                },
                processing: false,
                isFetching: false,
                airportResults: null,
            };
        },
        computed: {
            ...mapGetters(['trip', 'registration']),
            tvArranged: function() {
                return this.flights.filter(f => f.arranged_by === 'tv');
            },
            selfArranged: function() {
                return this.flights.filter(f => f.arranged_by === 'self');
            }
        },
        methods: {
            load: function(force) {
                async.series([
                    (cb) => {
                        if (this.registration.id) {
                            http.get('/app/registrations/' + this.registration.id + '/flights', {force}).then(response => {
                                this.flights = response.data;
                                cb();
                            });
                        }
                    }
                ], err => {

                });
            },
            searchAirports: debounce(function(name) {
                if (!name.length) {
                    this.airportResults = [];
                    return;
                }
                this.isFetching = true;
                http.get(`/api/airports/${name}`).then(response => {
                    this.airportResults = response.data;
                }).catch((error) => {
                    this.airportResults = [];
                    throw error;
                }).finally(() => {
                    this.isFetching = false;
                });
            }, 500),
            selectDepart(option) {
                this.newFlight.depart_airport = option;
                this.newFlight.depart_airport_id = option.id;
                this.newFlight.depart_tz = option.tz_database;
            },
            selectArrive(option) {
                this.newFlight.arrive_airport = option;
                this.newFlight.arrive_airport_id = option.id;
                this.newFlight.arrive_tz = option.tz_database;
            },
            clearDepart() {
                this.newFlight.depart_airport = null;
                this.newFlight.depart_airport_id = null;
                this.newFlight.depart_tz = null;
            },
            clearArrive() {
                this.newFlight.arrive_airport = null;
                this.newFlight.arrive_airport_id = null;
                this.newFlight.arrive_tz = null;
            },
            createFlight: function() {
                this.processing = true;
                http.post('/app/registrations/' + this.registration.id + '/flights', this.newFlight).then(response => {
                    this.processing = false;
                    this.addFlightModal = false;
                    this.load(true);
                });
            }
        },
        watch: {
            registration: function() {
                this.load(true);
            }
        },
        mounted() {
            this.load();
        }
    };
</script>

<style lang="scss" scoped>

</style>
