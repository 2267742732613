<template>
    <div class="content">
        <h3 class="mt-2 mb-5 ml-2 is-hidden-desktop"><i class="fal fa-file-signature fa-fw mr-3"></i>Payment Method</h3>

        <div class="content mt-6 mb-6" v-if="!documents">
            <h5 class="has-text-grey"><i class="fal fa-tennis-ball fa-spin mr-3"></i>Loading your information.  Please wait...</h5>
        </div>
        <div v-else>
            <h5 class="has-text-grey"><i class="fal fa-credit-card mr-3"></i>You currently have no information.  Please check back later.</h5>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import async from "async";
    import {client as http} from "../../http_client";
    import Flight from "../../components/Flight.vue";
    import Document from "../../components/Document.vue";

    export default {
        components: {Document, Flight},
        data: function() {
            return {
                documents: []
            };
        },
        computed: {
            ...mapGetters(['trip', 'registration']),
        },
        methods: {
            loadDocuments: function(force) {
                http.get('/app/user/documents', {force: true}).then(response => {
                    this.documents = response.data;
                });
            }
        },
        watch: {
            registration: function() {
                this.loadDocuments(true);
            }
        },
        mounted() {
            this.loadDocuments();
        }
    };
</script>

<style lang="scss" scoped>

</style>
