<template>
    <div class="content">
        <div class="content mt-6 mb-6" v-if="!participants">
            <h5 class="has-text-grey"><i class="fal fa-tennis-ball fa-spin mr-3"></i>Loading travelers. Please wait...</h5>
        </div>

        <section v-else>
            <div class="no-photo-warning" v-if="!currentUser.photo_file">
                <div class="columns is-mobile">
                    <div class="column is-narrow">
                        <i class="fal is-size-1 mr-2 fa-image-polaroid-user has-text-primary"></i>
                    </div>
                    <div class="column">
                        <p>
                            <strong>Hey good lookin'!</strong> It appears you haven't provided your profile picture yet.<br class="is-block-tablet is-hidden-mobile"/> Please complete your profile so your fellow travelers can recognize and get to know you!
                        </p>
                        <router-link :to="{name: 'Profile'}"><b-button type="is-primary"><i class="far fa-pencil mr-2"></i>Edit Profile</b-button></router-link>
                    </div>
                </div>
            </div>

            <div class="columns is-multiline is-mobile">
                <div class="column is-3-widescreen is-4-tablet is-12-mobile" v-for="p in participants">
                    <participant :participant="p" @click="gotoTraveler(p)"/>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import async from "async";
    import {client as http} from "../../http_client";
    import Participant from "../../components/Participant";

    export default {
        components: {Participant},
        data: function() {
            return {
                participants: null,
            };
        },
        computed: {
            ...mapGetters(['trip', 'registration', 'currentUser']),
        },
        methods: {
            load: function(force) {
                async.series([
                    (cb) => {
                        if (this.registration.id) {
                            http.get('/app/trips/' + this.registration.trip_id + '/participants', {force}).then(response => {
                                this.participants = response.data;
                                cb();
                            });
                        }
                    }
                ], err => {

                });
            },
            gotoTraveler: function(p) {
                this.$router.push({name: 'Traveler', params: {id: p.id}});
            }
        },
        watch: {
            registration: function() {
                this.load(true);
            }
        },
        mounted() {
            this.load();
        }
    };
</script>

<style lang="scss" scoped>
    .no-photo-warning {
        background-color: #e8f1dc;
        border: 2px solid #628b21;
        font-size: 1.2rem;
        border-radius: 10px;
        position: relative;
        padding: 1.25rem 2.5rem 1.25rem 1.5rem;
        margin-bottom: 4rem;
    }

    .profile-photo {
        margin: 0.05px 0.05px 1rem;
        background-color: white;
        border-radius: 0.3rem;
        padding: 1rem;
        cursor: pointer;
        text-align: center;
        font-size: 0.9rem;
        line-height: 1rem;

        i {
            font-size: 75px;
            color: #bebebe;
            display: block;
            margin: 0.8rem;
        }

        @media only screen and (max-width: 768px) {
            h4 {
                font-size: 1rem;
            }
        }

        .meta {
            font-size: 0.85rem;
            color: #4a4a4a;
        }

        .photo-frame {
            border: 1px solid #e7e7e7;
            display: inline-block;
            border-radius: 0.4rem;
            width: 150px;
            height: 150px;

            i {
                padding: 1.8rem 0;
            }
        }
    }
</style>
