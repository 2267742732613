import Vue from 'vue';
import Main from './Main.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

import Buefy from 'buefy';

// All options available at: https://buefy.org/documentation/constructor-options
Vue.use(Buefy, {
    defaultIconPack: 'fas'
});

import VueMoment from "vue-moment";
import moment from 'moment-timezone';

import VueMask from 'v-mask';
Vue.use(VueMask);

Vue.use(VueMoment, {
    moment
});

import Notifications from 'vue-notification';
Vue.use(Notifications);

import VueCurrencyFilter from 'vue-currency-filter';
Vue.use(VueCurrencyFilter,
    {
        symbol: '$',
        thousandsSeparator: ',',
        fractionCount: 2,
        fractionSeparator: '.',
        symbolPosition: 'front',
        symbolSpacing: false
    });

import {axiosInstance} from "./http_client";
import './registerServiceWorker'

const token = localStorage.getItem('token');
if (token) {
    console.log('Setting token.');
    axiosInstance.defaults.headers.common['Authorization'] = token;
}

axiosInstance.interceptors.response.use(function (response) {
    return response;
}, function (err) {
    if (err.response.status === 401) {
        console.log('Received 401, signing out.');
        store.dispatch('signOut');
        if (router.currentRoute.path !== '/') router.push('/');
    }
    return Promise.reject(err);
});

new Vue({
    router,
    store,
    render: h => h(Main)
}).$mount('#app');

Vue.filter('tel', function (phone) {
    if (phone) {
        return phone.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    } else {
        return 'N/A';
    }
});

Vue.filter('capitalize', function (value) {
    if (value) {
        return value.toLowerCase().replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
    } else {
        return '';
    }
});

Vue.filter('uppercase', function (value) {
    if (value) {
        return value.toUpperCase();
    } else {
        return '';
    }
});
