<template>
    <div class="content">
        <h3 class="mt-2 mb-5 ml-2 is-hidden-desktop"><i class="fal fa-credit-card-front fa-fw mr-3"></i>Trip Cost & Payments</h3>

        <div class="content mt-6 mb-6" v-if="!trip || !plan || !registration">
            <h5 class="has-text-grey"><i class="fal fa-tennis-ball fa-spin mr-3"></i>Loading your details.  Please wait...</h5>
        </div>

        <div v-else>
            <div class="white-container content">
                <div class="columns is-mobile">
                    <div class="column is-narrow pr-0"><i class="fal fa-file-invoice fa-fw is-size-4"></i></div>
                    <div class="column">
                        <h4>Trip Cost Breakdown</h4>
                        <div class="columns mt-5">
                            <div class="column pb-0">
                                <h6 class="mb-1">{{ trip.title }}</h6>
                                <div class="is-size-7">{{ trip.start_on | moment('MMMM, Do, YYYY') }} - {{ trip.end_on | moment('MMMM, Do, YYYY') }}</div>
                                <div class="is-size-7">
                                    {{ registration.user.first_name }} {{ registration.user.last_name }}<span v-if="registration.second_id">,
                                {{ registration.second_user.first_name }} {{ registration.second_user.last_name }}
                            </span>
                                </div>
                                <div class="is-size-7">{{ trip.cost | currency }} per person</div>
                            </div>
                            <div class="column has-text-right pb-0 is-narrow">
                                {{ trip.cost * ((registration.second_id) ? 2 : 1) | currency }}
                            </div>
                        </div>
                        <hr class="mt-0 mb-3"/>

                        <div v-for="a in registration.addons">
                            <div class="columns">
                                <div class="column pb-0">
                                    <h6 class="mb-1">{{ a.title }}</h6>
                                    <div class="is-size-7">
                                        {{ a.pp | currency }} per person
                                        <span v-if="a.type === 'departure'"> &mdash; Flight Departure</span>
                                        <span v-if="a.type === 'extensions'"> &mdash; Trip Extension</span>
                                        <span v-if="a.type === 'airfare'"> &mdash; Airfare/Upgrade</span>
                                        <span v-if="a.type === 'accomodations'"> &mdash; Accommodations/Upgrade</span>
                                        <span v-if="a.type === 'tennis'"> &mdash; Tennis/Upgrade</span>
                                    </div>
                                </div>
                                <div class="column has-text-right pb-0 is-narrow">
                                    {{ a.total | currency }}
                                </div>
                            </div>
                            <hr class="mt-0 mb-3"/>
                        </div>

                        <div class="columns mb-4">
                            <div class="column pb-0 has-text-right">
                                <h5 class="mb-0">Total</h5>
                            </div>
                            <div class="column has-text-right pb-0 is-narrow">
                                {{ total | currency }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="white-container">
                <div class="columns is-mobile">
                    <div class="column is-narrow pr-0"><i class="fal fa-money-check-dollar fa-fw is-size-4"></i></div>
                    <div class="column">
                        <h4 class="mb-5 pb-3">Payment Plan</h4>

                        <div v-for="i in plan.items">
                            <div class="columns">
                                <div class="column pb-0">
                                    <h6 class="mb-1">{{ i.title }}</h6>
                                    <div class="is-size-7">
                                        {{i.due_on | moment('MMM D, YYYY')}}
                                    </div>
                                </div>
                                <div class="column has-text-right pb-0 is-narrow">
                                    <b-tag size="is-small" v-if="i.paid===1" class="mr-3"><i class="fas fa-fw mr-1 fa-check has-text-success"></i> Paid</b-tag>
                                    <b-tag size="is-small" v-else class="mr-3"><i class="fas fa-fw mr-1 fa-ban"></i> Unpaid</b-tag>
                                    {{ i.total | currency }}
                                </div>
                            </div>
                            <hr class="mt-0 mb-3"/>
                        </div>

                        <div class="columns mb-4 is-mobile">
                            <div class="column pb-0 has-text-right">
                                <div class="has-text-weight-bold">Total</div>
                                <div class="has-text-weight-bold">Paid</div>
                                <div class="has-text-weight-bold">Remaining</div>
                            </div>
                            <div class="column has-text-right pb-0 is-narrow">
                                <div>{{ plan.total | currency }}</div>
                                <div>{{ plan.paid | currency }}</div>
                                <div>{{ (plan.total - plan.paid) | currency }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import async from "async";
    import {client as http} from "../../http_client";

    export default {
        data: function() {
            return {
                plan: null
            };
        },
        computed: {
            ...mapGetters(['trip', 'registration']),
            total: function() {
                let total = (this.registration.second_id) ? (this.trip.cost * 2) : this.trip.cost;
                for (let i = 0; i < this.registration.addons.length; i++) {
                    const addon = this.registration.addons[i];
                    total += addon.total;
                }
                return total;
            },
            itemsTotal: function() {
                let total = 0;
                for (let i = 0; i < this.plan.items.length; i++) {
                    const item = this.plan.items[i];
                    total += (item.amount * item.quantity)
                }
                return total;
            },
            itemsPaid: function() {
                let total = 0;
                for (let i = 0; i < this.plan.items.length; i++) {
                    const item = this.plan.items[i];
                    if (item.paid === 1) total += (item.amount * item.quantity)
                }
                return total;
            },
            itemsRemaining: function() {
                let total = 0;
                for (let i = 0; i < this.plan.items.length; i++) {
                    const item = this.plan.items[i];
                    if (item.paid === 0) total += (item.amount * item.quantity)
                }
                return total;
            }
        },
        methods: {
            load: function(force) {
                if (this.registration.id) {
                    http.get('/app/registrations/' + this.registration.id + '/billing', {force}).then(response => {
                        this.plan = response.data;

                        for (let i = 0; i < this.registration.addons.length; i++) {
                            const addon = this.registration.addons[i];
                            addon.title = (addon.override_title) ? addon.override_title : addon.title;

                            if (addon.override_price !== null || addon.override_price_double !== null) {
                                addon.override = true;

                                if (this.registration.second_id) {
                                    addon.pp = addon.override_price_double / 2;
                                    addon.quantity = 2;
                                    addon.total = addon.override_price_double;
                                } else {
                                    addon.pp = addon.override_price;
                                    addon.quantity = 1;
                                    addon.total = addon.override_price;
                                }
                            } else {
                                if (this.registration.second_id) {
                                    addon.pp = addon.double_cost / 2;
                                    addon.quantity = 2;
                                    addon.total = addon.double_cost;
                                } else {
                                    addon.pp = addon.single_cost;
                                    addon.quantity = 1;
                                    addon.total = addon.single_cost;
                                }
                            }
                        }
                    });
                }
            }
        },
        watch: {
            registration: function() {
                this.load();
            }
        },
        mounted() {
            this.load();
        }
    };
</script>

<style lang="scss" scoped>

</style>
