<template>
    <div class="content">
        <h3 class="mt-2 mb-5 ml-2"><i class="fal fa-arrow-right fa-fw mr-3 ml-3"></i>Choose a Trip</h3>

        <div class="content mt-6 mb-6" v-if="!registrations">
            <h5 class="has-text-grey"><i class="fal fa-tennis-ball fa-spin mr-3"></i>Loading your trips. Please wait...</h5>
        </div>

        <div class="content" v-else>
            <div class="white-container">
                <h4 class="mt-2 mb-5"><i class="far fa-fw mr-2 has-text-primary"></i>Upcoming/Current Trips</h4>
                <div class="trip-menu-item" v-for="r in currentTrips" @click="chooseTrip(r)">
                    <div class="columns is-gapless">
                        <div class="column is-narrow pb-0">
                            <i class="far fa-fw fa-tennis-ball mr-2 has-text-primary"></i>
                        </div>
                        <div class="column pb-0">
                            <strong class="has-text-black">{{ r.trip.title }}</strong>
                            <div class="is-size-7">
                                {{ r.dates }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="white-container mt-6">
                <h4 class="mt-2 mb-5"><i class="far fa-fw mr-2 has-text-primary"></i>Past Trips</h4>
                <div class="trip-menu-item" v-for="r in pastTrips" @click="chooseTrip(r)">
                    <div class="columns is-gapless">
                        <div class="column is-narrow pb-0">
                            <i class="far fa-fw fa-tennis-ball mr-2 has-text-primary"></i>
                        </div>
                        <div class="column pb-0">
                            <strong class="has-text-black">{{ r.trip.title }}</strong>
                            <div class="is-size-7">
                                {{ r.dates }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import async from "async";
    import {client as http} from "../http_client";
    import moment from "moment";

    export default {
        data: function() {
            return {
                registrations: null,
                loading: false
            };
        },
        computed: {
            currentTrips: function() {
                let regs = this.registrations.filter(r => {
                    return moment(r.trip.end_on).isAfter(moment());
                });

                return regs.sort((a, b) => {
                    return moment(a.trip.start_on).isAfter(moment(b.trip.start_on)) ? 1 : -1;
                });
            },
            pastTrips: function() {
                let regs = this.registrations.filter(r => {
                    return moment(r.trip.end_on).isBefore(moment());
                });

                return regs.sort((a, b) => {
                    return moment(a.trip.start_on).isBefore(moment(b.trip.start_on)) ? 1 : -1; // Reverse the comparison logic
                });
            }
        },
        methods: {
            ...mapActions(['loadRegistration', 'loadTrip']),
            load: function(force) {
                http.get('/app/registrations/', {force}).then(response => {
                    for (let i = 0; i < response.data.length; i++) {
                        let reg = response.data[i];
                        let s = moment(reg.trip.start_on);
                        let e = moment(reg.trip.end_on);
                        if (s.isSame(e, 'day')) {
                            reg.dates = s.format('MMMM Do, YYYY');
                        } else if (s.isSame(e, 'month')) {
                            reg.dates = s.format('MMMM Do') + ' - ' + e.format('Do, YYYY');
                        } else if (s.isSame(e, 'year')) {
                            reg.dates = s.format('MMMM Do') + ' - ' + e.format('MMMM Do, YYYY');
                        } else {
                            reg.dates = s.format('MMMM Do, YYYYY') + ' - ' + e.format('MMMM Do, YYYY');
                        }
                    }

                    this.registrations = response.data;
                });
            },
            chooseTrip: function(reg) {
                this.loading = true;
                async.series([
                    (cb) => {
                        this.loadRegistration(reg.id).then(response => {
                            cb();
                        });
                    },
                    (cb) => {
                        this.loadTrip(reg.trip_id).then(response => {
                            cb();
                        });
                    }
                ], err => {
                    this.$router.push({name: 'Today'});
                });
            }
        },
        watch: {},
        mounted() {
            this.load();
        }
    };
</script>

<style lang="scss" scoped>
    .trip-menu-item {
        padding: 10px;
        cursor: pointer;
        margin: 0 0 5px 25px;

        &:hover {
            border-radius: 5px;
            background-color: #f4f4f4;
        }
    }
</style>
