<template>
    <div class="content">
        <h3 class="mt-2 mb-5 ml-2 is-hidden-desktop"><i class="fal fa-passport fa-fw mr-3"></i>Travel Requirements</h3>

        <div class="white-container content ql-editor">
            <div v-html="trip.app_travel" v-if="trip.app_travel"></div>
            <div v-else class="has-text-centered">
                <h4 class="pt-6 pb-6 has-text-grey"><i class="fal fa-message-captions mr-2"></i> More information will be available later.  Check back soon!</h4>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import async from "async";
    import {client as http} from "../../http_client";

    export default {
        data: function() {
            return {
                flights: []
            };
        },
        computed: {
            ...mapGetters(['trip', 'registration']),
        }
    };
</script>

<style lang="scss" scoped>

</style>
